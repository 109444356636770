var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ManagementLayout',{attrs:{"show":!_vm.isLoadingTable,"control-show":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Projects")]},proxy:true},{key:"subtitle",fn:function(){return [(_vm.permissionsCheck)?_c('span',[_vm._v(" View and manage your team's "),_c('ExternalLink',{attrs:{"href":"https://docs.prefect.io/cloud/concepts/projects.html#projects"}},[_vm._v("projects")])],1):_c('span',[_vm._v("View the "),_c('ExternalLink',{attrs:{"href":"https://docs.prefect.io/cloud/concepts/projects.html#projects"}},[_vm._v("projects")]),_vm._v(" of "+_vm._s(_vm.tenant.name))],1)]},proxy:true},(_vm.permissionsCheck)?{key:"cta",fn:function(){return [_c('v-btn',{staticClass:"white--text",attrs:{"color":"primary","large":""},on:{"click":function($event){_vm.selectedProject = null
        _vm.dialogCreateProject = true
        _vm.projectNameInput = ''
        _vm.projectDescriptionInput = ''}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" view_carousel ")]),_vm._v(" Add Project ")],1)]},proxy:true}:null],null,true)},[(!_vm.$vuetify.breakpoint.mdAndUp)?_c('v-text-field',{staticClass:"rounded-0 elevation-1 mb-1",attrs:{"solo":"","dense":"","hide-details":"","single-line":"","placeholder":"Search by project ID, name, or description","prepend-inner-icon":"search","autocomplete":"new-password"},model:{value:(_vm.searchInput),callback:function ($$v) {_vm.searchInput=$$v},expression:"searchInput"}}):_vm._e(),_c('v-card',{attrs:{"tile":""}},[_c('v-card-text',{staticClass:"pa-0"},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"py-1 mr-2 flex"},[_c('v-text-field',{staticClass:"rounded-0 elevation-1",style:({
            'max-width': _vm.$vuetify.breakpoint.mdAndUp ? '360px' : null
          }),attrs:{"solo":"","dense":"","hide-details":"","single-line":"","placeholder":"Search by project ID, name, or description","prepend-inner-icon":"search","autocomplete":"new-password"},model:{value:(_vm.searchInput),callback:function ($$v) {_vm.searchInput=$$v},expression:"searchInput"}})],1):_vm._e(),_c('v-data-table',{staticClass:"elevation-2 rounded-0 truncate-table",class:{ 'fixed-table': _vm.$vuetify.breakpoint.smAndUp },attrs:{"fixed-header":"","headers":_vm.headersByViewport,"header-props":{ 'sort-icon': 'arrow_drop_up' },"search":_vm.searchInput,"items":_vm.projects,"items-per-page":10,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"footer-props":{
          showFirstLastPage: true,
          itemsPerPageOptions: [10, 15, 20, -1],
          firstIcon: 'first_page',
          lastIcon: 'last_page',
          prevIcon: 'keyboard_arrow_left',
          nextIcon: 'keyboard_arrow_right'
        },"no-results-text":"No projects found. Try expanding your search?","no-data-text":"This team does not have any projects yet."},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"header.name",fn:function(ref){
        var header = ref.header;
return [_c('span',{staticClass:"text-subtitle-2"},[_vm._v(_vm._s(header.text))])]}},{key:"header.description",fn:function(ref){
        var header = ref.header;
return [_c('span',{staticClass:"text-subtitle-2"},[_vm._v(_vm._s(header.text))])]}},{key:"header.active_flow_count.aggregate.count",fn:function(ref){
        var header = ref.header;
return [_c('span',{staticClass:"text-subtitle-2"},[_vm._v(_vm._s(header.text))])]}},{key:"header.created",fn:function(ref){
        var header = ref.header;
return [_c('span',{staticClass:"text-subtitle-2"},[_vm._v(_vm._s(header.text))])]}},{key:"header.id",fn:function(ref){
        var header = ref.header;
return [_c('span',{staticClass:"text-subtitle-2"},[_vm._v(_vm._s(header.text))])]}},{key:"item.id",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('div',_vm._g({staticClass:"cursor-pointer text-truncate",on:{"click":function($event){return _vm.copyTextToClipboard(item.id)}}},on),[_vm._v(" "+_vm._s(item.id)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.copiedProjectId === item.id ? 'Copied!' : 'Click to copy ID'))])])]}},{key:"item.name",fn:function(ref){
        var item = ref.item;
return [(item.name)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('div',_vm._g({staticClass:"hidewidth"},on),[_c('router-link',{attrs:{"to":{
                    name: 'project',
                    params: {
                      id: item.id,
                      tenant: _vm.tenant.slug
                    }
                  }}},[_vm._v(" "+_vm._s(item.name)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.name))])]):_c('span',[_vm._v("-")])]}},{key:"item.created",fn:function(ref){
                  var item = ref.item;
return [(item.created)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('div',_vm._g({staticClass:"hidewidth"},on),[_vm._v(" "+_vm._s(_vm.formDate(item.created))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.formatDateTime(item.created)))])]):_c('span',[_vm._v("-")])]}},{key:"item.description",fn:function(ref){
                  var item = ref.item;
return [(item.description)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('div',_vm._g({staticClass:"hidewidth"},on),[_vm._v(" "+_vm._s(item.description)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.description))])]):_c('span',[_vm._v("-")])]}},(_vm.permissionsCheck)?{key:"item.actions",fn:function(ref){
                  var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","fab":"","x-small":"","color":"primary"},on:{"click":function($event){_vm.selectProject(item)
                  _vm.dialogModifyProject = true}}},on),[_c('v-icon',[_vm._v("edit")])],1)]}}],null,true)},[_vm._v(" Modify this project ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","fab":"","x-small":"","color":"error"},on:{"click":function($event){_vm.selectProject(item)
                  _vm.dialogRemoveProject = true}}},on),[_c('v-icon',[_vm._v("delete")])],1)]}}],null,true)},[_vm._v(" Delete this project ")])]}}:null],null,true)})],1)],1),_c('ConfirmDialog',{attrs:{"dialog-props":{ 'max-width': '600' },"disabled":_vm.isCreatingProject || !_vm.createFormValid,"loading":_vm.isCreatingProject,"title":"Create a new project","confirm-text":"Create"},on:{"cancel":_vm.closeProjectDialog,"confirm":_vm.createProject},model:{value:(_vm.dialogCreateProject),callback:function ($$v) {_vm.dialogCreateProject=$$v},expression:"dialogCreateProject"}},[_c('v-form',{model:{value:(_vm.createFormValid),callback:function ($$v) {_vm.createFormValid=$$v},expression:"createFormValid"}},[_c('v-text-field',{attrs:{"autofocus":"","label":"Project Name","counter":"","maxlength":"30","error-messages":_vm.projectNameErrors,"validate-on-blur":"","outlined":"","dense":""},on:{"focus":function($event){_vm.projectNameErrors = []
          _vm.showNameInputIcon = false},"blur":_vm.checkProjectName},model:{value:(_vm.projectNameInput),callback:function ($$v) {_vm.projectNameInput=$$v},expression:"projectNameInput"}},[(_vm.showNameInputIcon && _vm.projectNameErrors.length === 0)?_c('v-icon',{staticClass:"green--text",attrs:{"slot":"append"},slot:"append"},[_vm._v(" check ")]):_vm._e(),(_vm.showNameInputIcon && _vm.projectNameErrors.length > 0)?_c('v-icon',{staticClass:"red--text",attrs:{"slot":"append"},slot:"append"},[_vm._v(" clear ")]):_vm._e()],1),_c('v-text-field',{staticClass:"mt-3",attrs:{"label":"Project Description","counter":"","outlined":"","dense":"","maxlength":"180"},model:{value:(_vm.projectDescriptionInput),callback:function ($$v) {_vm.projectDescriptionInput=$$v},expression:"projectDescriptionInput"}})],1)],1),(_vm.selectedProject)?_c('ConfirmDialog',{attrs:{"dialog-props":{ 'max-width': '600' },"disabled":_vm.isModifyingProject || !_vm.modifyFormValid,"loading":_vm.isModifyingProject,"title":("Modify the project " + (_vm.selectedProject.name)),"confirm-text":"Save"},on:{"cancel":_vm.closeProjectDialog,"confirm":_vm.modifyProject},model:{value:(_vm.dialogModifyProject),callback:function ($$v) {_vm.dialogModifyProject=$$v},expression:"dialogModifyProject"}},[_c('v-form',{model:{value:(_vm.modifyFormValid),callback:function ($$v) {_vm.modifyFormValid=$$v},expression:"modifyFormValid"}},[_c('v-text-field',{attrs:{"autofocus":"","label":"Project Name","counter":"","maxlength":"30","error-messages":_vm.projectNameErrors,"validate-on-blur":"","outlined":"","dense":""},on:{"focus":function($event){_vm.projectNameErrors = []
          _vm.showNameInputIcon = false},"blur":_vm.checkProjectName},model:{value:(_vm.projectNameInput),callback:function ($$v) {_vm.projectNameInput=$$v},expression:"projectNameInput"}},[(_vm.showNameInputIcon && _vm.projectNameErrors.length === 0)?_c('v-icon',{staticClass:"green--text",attrs:{"slot":"append"},slot:"append"},[_vm._v(" check ")]):_vm._e(),(_vm.showNameInputIcon && _vm.projectNameErrors.length > 0)?_c('v-icon',{staticClass:"red--text",attrs:{"slot":"append"},slot:"append"},[_vm._v(" clear ")]):_vm._e()],1),_c('v-text-field',{staticClass:"mt-3",attrs:{"label":"Project Description","counter":"","maxlength":"180","outlined":"","dense":""},model:{value:(_vm.projectDescriptionInput),callback:function ($$v) {_vm.projectDescriptionInput=$$v},expression:"projectDescriptionInput"}})],1)],1):_vm._e(),(_vm.selectedProject)?_c('ConfirmDialog',{attrs:{"type":"error","dialog-props":{ 'max-width': '600' },"disabled":_vm.isRemovingProject,"loading":_vm.isRemovingProject,"title":("Are you sure you want to delete " + (_vm.selectedProject.name) + "?")},on:{"cancel":_vm.closeProjectDialog,"confirm":_vm.removeProject},model:{value:(_vm.dialogRemoveProject),callback:function ($$v) {_vm.dialogRemoveProject=$$v},expression:"dialogRemoveProject"}},[(
        _vm.selectedProject.activeFlowCount || _vm.selectedProject.archivedFlowCount
      )?[_c('p',{staticClass:"mb-6"},[_vm._v(" "+_vm._s(_vm.displayFlowCountMessage)+". If you would like to preserve these flows, you can move them into a new project now. ")]),_c('v-autocomplete',{attrs:{"data-public":"","items":_vm.moveToProjectDropdownItems,"menu-props":{
          'offset-y': true,
          transition: 'slide-y-transition'
        },"outlined":"","dense":"","label":"New Project","clearable":""},model:{value:(_vm.moveToProjectInput),callback:function ($$v) {_vm.moveToProjectInput=$$v},expression:"moveToProjectInput"}}),(_vm.moveToProjectInput)?_c('p',{staticClass:"mb-0"},[_vm._v(" Select CONFIRM to delete this project and move its flows to "),_c('strong',[_vm._v(_vm._s(_vm.moveToProjectInput.name))]),_vm._v(". ")]):_c('p',{staticClass:"mb-0"},[_vm._v(" If you choose to leave the \"New project\" field blank, "),_c('strong',{staticClass:"red--text"},[_vm._v(" you will delete this project and all of its flows. This action cannot be undone. ")])])]:_vm._e(),(
        _vm.selectedProject.activeFlowCount === 0 &&
          _vm.selectedProject.archivedFlowCount === 0
      )?[_c('p',{staticClass:"mb-2"},[_vm._v(" This project has no flows and can be safely deleted. ")])]:_vm._e()],2):_vm._e(),_c('Alert',{attrs:{"type":_vm.alertType,"message":_vm.alertMessage,"offset-x":_vm.$vuetify.breakpoint.mdAndUp ? 256 : 56},model:{value:(_vm.alertShow),callback:function ($$v) {_vm.alertShow=$$v},expression:"alertShow"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }